body {
    font-size: 13px;
    line-height: 1.5;
    font-family: 'Rubik', sans-serif;
    background-color: #021321;
    background-image: url('../src/assets/body-bg.jpg');
    background-attachment: fixed;
    background-size: cover;

}

a {
    color: #e5f3f6;
    text-decoration: none;
    -webkit-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

a:hover,
button:hover {
    text-decoration: none;
    transition: all .4s ease-out 0s;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    /* border: 1px solid #fefefe30;
    -webkit-text-fill-color: #cfd4cf;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
    transition: background-color 5000s ease-in-out 0s;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

body:before {
    display: none;
    content: "";
    background: url(../src/assets/bg-1.png) no-repeat 0 0 transparent;
    width: 60%;
    height: 100%;
    background-size: cover;
    right: 0;
    mix-blend-mode: screen;
    z-index: -1;
    position: fixed;
    animation: animation-02 19s infinite linear alternate;
    max-width: 1220px;
}

main:after {
    content: "";
    background: url(../src/assets/star-min.png) no-repeat 0 0 transparent;
    position: absolute;
    width: 50%;
    height: 300px;
    background-size: cover;
    z-index: -1;
    top: unset;
    right: 0;
    bottom: -35px;
    max-width: 800px;
    animation: animation-01 8s infinite cubic-bezier(0.59, 0.26, 0.48, 0.72) alternate;
    opacity: 0;

}

header {
    border-bottom: 1px solid #ffffff1f;
}

header .container-fluid {
    max-width: 1700px;
}

.offcanvas-menu {
    background: #070f25;
    max-width: 80%;
}

.offcanvas-menu:after {
    mix-blend-mode: screen;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../src/assets/bg-1.png) no-repeat top center transparent;
    background-size: cover;
    top: 0;
    z-index: -1;
    opacity: 0.3;
}

.offcanvas-menu:before {
    background: url(../src/assets/body-bg.png) no-repeat 0 0 #021321;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.6;
}

.mobile-nav {
    display: block;
    margin-bottom: 2rem;
}

.mobile-nav li a {
    padding: 16px 0px;
    display: block;
    border-bottom: 1px solid #ffffff14;
}

.offcanvas-header {
    color: white;
}

.offcanvas-header h5 {
    font-weight: 500;
}

.offcanvas-body {
    padding: 0px 20px;
}

.close-button {
    background: transparent;
    color: #29fff1;
    font-size: 28px;
    height: 30px;
    line-height: 27px;
    opacity: .8;
    width: 30px;
    display: inline-block;
    position: absolute;
    right: 28px;
    border: 0;
}

.close-button i {
    margin: 0;
    padding: 0;
}

.close-button:hover {
    opacity: 1;
}

.switch-mode {
    width: 44px;
    height: 44px;
    border: 0;
    padding: 0;
    margin: 0px 20px;
    background: #1950ac;
    color: white;
    border-radius: 50%;
}

.switch-mode:hover {
    background: #0f45a1;
}

ul.main-nav {
    margin: 0;
    padding: 0;
}

.main-nav > li > a, .main-nav > li > button {
    position: relative;
    padding: 34px 1rem;
    font-size: 13px;
    color: white;
    align-items: center !important;
    display: flex !important;
    text-transform: uppercase;
    background: no-repeat;
    border: 0;
}

.main-nav .nav-item {
    position: relative;
}

.main-nav li a:hover, .main-nav li a.active, .main-nav li button:hover, .main-nav li button.active {
    color: #33D1EB;
}

.main-nav li a:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 1px;
    left: 0;
    background-image: linear-gradient(to right, #080a1700 0%, #28b1be 51%, #00000000 100%);
    transition: 0.5s;
    right: 0;
    margin: 0px auto;
}

.main-nav > li > a.active:before {
    width: 80%;
    transition: 0.5s;
}

.mobile-links > li > a, .mobile-links > li > button {
    line-height: 1;
    padding: 16px 12px !important;
    margin: 0;
    text-align: center;
    justify-content: center !important;
}

.mobile-links li a:before {
    opacity: 0;
}

.mobile-links {
    justify-content: flex-end !important;
}

.mobile-links li a i, .mobile-links li button i {
    margin-right: 0 !important;
}

.btn {
    font-size: 12px;
    padding: 18px 24px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    border: 0;
    background-size: 200% auto;
    -webkit-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.btn-primary {
    background: -webkit-gradient(linear, left top, right top, from(#c90075), color-stop(50%, #de00ac), to(#c90075));
    background: linear-gradient(to right, #be0eca 0%, #4b258d 50%, #c90075 100%);
    background-size: 200% auto;
}

.btn-primary:hover {
    background-position: right center;
    text-decoration: none;
}

.button-secondary {
    background: #2171d4;
    color: #ffffff;
    font-weight: 500;
    padding: 12px 0;
    border-radius: 36px;
    border: 2px solid transparent;
    text-align: center;
}

.button-secondary:hover {
    color: white !important;
    background: #185eb3;
}

.button-switch {
    background: #8c20b4;
    color: #ffffff;
    font-weight: 500;
    padding: 12px 0;
    border-radius: 36px;
    border: 2px solid transparent;
}

.button-switch:hover {
    background: #7c19a0;
    color: #ffffff !important;
}

.menu-control:before {
    content: "\e6c2";
    font-family: 'themify';
}

.menu-control {
    background: none;
    border: none;
    color: #09ebc5;
    font-size: 21px;
}

/* notification */
.notification-button::before {
    opacity: 0;
}

.notification-button {
    color: white !important;
    position: relative !important;
    font-size: 16px !important;
}

.notification-button span {
    background: #9d1d7e;
    width: 24px;
    height: 24px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    top: 19px;
    font-size: 13px;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}

.notification-button::after {
    display: none;
}

.notification .dropdown-menu {
    right: 0;
    left: unset;
    background: #060c1c;
    padding: 24px 24px !important;
    min-width: 280px;
    color: white;
    margin-top: 4px;
}

.notification .dropdown-menu h5 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.notification .dropdown-menu p, .notification .dropdown-menu p a {
    font-size: 13px;
    font-weight: 400;
    color: #656d8b;
    margin: 0;
}

.notification .dropdown-menu p a:hover {
    color: #838cad;
}

.notification .dropdown-menu li {
    border-bottom: 1px solid #ffffff0f;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.notification .dropdown-menu li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.notification-info {
    font-size: 12px;
    color: #656d8b;
    margin-top: 16px;
}

.remove-notifications {
    width: 100%;
    background: #070f23;
    display: block;
    text-align: center;
    padding: 0;
    transition: all .4s ease-out 0s;
}

.remove-notifications:hover {
    background: #0a152f;
}

.remove-notifications button {
    background: none;
    border: 0;
    font-size: 13px;
    padding: 14px 0;
    color: #12d8e7;
    display: block;
    text-align: center;
    width: 100%;
}

/* notification */

main {
    padding: 3rem 0;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.date-filter {
    background: #071A2A;
    padding: 0;
    margin: 0;
    border-radius: 36px;
}

.filter-title {
    color: white;
    line-height: 44px;
    padding: 0px 1.4rem;
    border-radius: 36px;
    font-size: 14px;
}

.filter-options {
    padding: 0;
}

.filter-options {
    justify-content: flex-start !important;
    display: flex !important;
    align-items: center !important;
}

.filter-options li button {
    background: transparent;
    color: white;
    border: 0;
    padding: 0px 12px;
    line-height: 44px;
    font-size: 15px;
}

.filter-options li button.active {
    color: #33D1EB;
}

.version-info {
    color: #BBD2E4;
}

.version-info li {
    font-size: 14px;
    align-items: center !important;
    display: flex;
}

.version-info li img,
.version-info li svg {
    margin-right: 6px;
}

.page-title {
    background: #0a112ad9;
    padding: 32px 26px;
    border-radius: 16px;
    color: white;
}

.page-title h1 {
    font-size: 28px;
    margin: 0;
}

.page-title p {
    color: #8ba3a3;
    margin: 0;
}

.title-icon {
    background: #571ada;
    border-radius: 9px;
    font-size: 26px;
    margin-right: 14px;
    min-width: 54px;
    padding: 8px 12px;
    text-align: center;
    height: 56px;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;

}

.card-style-1 {
    background: #061929c9;
    padding: 26px 26px 0px 26px;
    border-radius: 16px;
}

.card-title {
    color: #8ba3a3;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

.card-value {
    font-size: 24px;
    color: white;
}

.sub-text {
    color: #33D1EB;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.card-right img {
    position: absolute;
    right: 20px;
    bottom: 0;
}

.card-style-2 {
    background: #0a112ad9;
    padding: 26px;
    border-radius: 16px;
}
.card-style-2 p {
    color: #8ba3a3;
}
.card-style-2 .card-value {
    font-size: 15px;
    color: #33D1EB;
}

.card-top {
    margin-bottom: 30px;
}

.chart img {
    max-width: 100%;
    height: auto;
}

.faq-accordion {
    background: transparent !important;
    counter-reset: accordioncounter;
}

.faq-accordion .accordion-item {
    background: #0a112ad9;
    color: white;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 300;
    border: 0;
    margin-bottom: 15px;
}

.faq-accordion .accordion-item .accordion-body {
    border: 0;
    padding: 0px 20px 20px 20px;
}

.faq-accordion .accordion-header button {
    background: transparent;
    color: #0fd9ff;
    padding: 24px 52px 24px 20px;
    border: 0;
    box-shadow: none;
    font-size: 17px;
}

.faq-accordion .accordion-header button:after {
    content: "\f078";
    font-family: 'FontAwesome';
    background: no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    text-align: center;
    right: 20px;
    line-height: 24px;
}

.faq-accordion .accordion-header button:before {
    counter-increment: accordioncounter;
    content: counters(accordioncounter, ".") ".";
    margin: 0 1rem;
    color: #fff;
}

/* Governance */
.proposal {
    background: #061929c9;
    padding: 2.6rem 2rem;
    border-radius: 16px;
    color: white;
    position: relative;
}

.proposal-results {
    margin-top: 2rem;
}

.proposal .proposal-header h2 {
    font-size: 24px;
    margin-bottom: 0;
}

.proposal .proposal-header h2 a {
    color: white;
}

.proposal .proposal-timestamp {
    color: #8ba3a3;
}

.proposal-timestamp:before {
    font-family: "Font Awesome 6 Pro";
    margin-right: 6px;
    content: "\f274";
    color: #33d1eb;
}

.proposal .proposal-status {
    background: #c0147d;
    padding: 6px 18px;
    border-radius: 36px;
    font-size: 12px;
    font-weight: 500;
}

.proposal .proposal-header {
    position: relative;
    margin-bottom: 1rem;
    align-items: flex-start;
    justify-content: space-between !important;
    display: flex !important;
}

.proposal .proposal-summary {
    color: #8ba3a3;
    font-size: 15px;
    max-width: 600px;
}

.proposal-results .progress {
    margin-bottom: 1.6rem;
    height: 42px;
    border-radius: 36px;
    background: #0d1f31;
    position: relative;
}

.proposal-results .progress:last-child {
    margin: 0;
}

.proposal-results .progress-bar {
    flex-direction: row;
    align-items: center !important;
    background: #4b14c0 !important;
    justify-content: flex-start !important;
    border-radius: 36px;
    overflow: visible;
}

.proposal-options {
    position: absolute;
    left: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    text-align: left;
}

.proposal-results .progress-bar span:first-child {
    margin-right: 20px;
}

.proposal-results .progress-bar i {
    margin-right: 10px;
}

/* Governance */

/* not found */
.not-found {
    color: white;
    text-align: center;
    padding: 6rem 0;
}

.not-found p {
    color: #76899d;
    font-size: 16px;
    max-width: 500px;
    margin: 0px auto;
}

.not-found .alert-icon {
    font-size: 4rem;
    color: #c91ddb;
    margin-bottom: 2rem;
}
.not-found h1 {
    font-size: 3rem;
}
.not-found .success-icon {
    font-size: 4rem;
    color: #33D1EB;
    margin-bottom: 2rem;
}
/* not found */
/* proposal table */
.search {
    position: relative;
}

.search-box {
    background: #091028;
    border: 0;
    padding: 15px 20px;
    color: white;
    font-size: 13px;
    transition: all .4s ease-out 0s;
}

.search-box:focus {
    background: #0d1634;
    box-shadow: none;
    color: white;
}

.search-box::placeholder {
    color: #728fa9;
}

.search-button {
    position: absolute;
    right: -20px;
    top: 0;
    background: #a12583;
    border: 0;
    border-radius: 9px;
    width: 52px;
    height: 49px;
    line-height: 30px;
    color: white;
    font-size: 13px;
    transition: all .4s ease-out 0s;
}

.search-button:hover {
    background: #951575;
}

.proposal-table {
    color: white;
    font-size: 14px;
}

.topic-row {
    width: 75%;
    min-width: 270px;
    text-align: left !important;
}

.topic-title h3 {
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
    align-items: center;
    display: flex;
}

.topic-title h3 a:hover {
    color: #0adbe3;
}

.topic-title h3 i {
    color: #0adbe3;
}
button.edit-proposal {
    background: none;
    border: 0;
    font-size: 13px;
}
.topic-title p {
    font-size: 14px;
    font-weight: 400;
    color: #656d8b;
    margin-bottom: 8px;
}

.proposal-table thead {
    border-bottom: 1px solid #0c1f33;
}

.proposal-table thead tr th {
    padding: 14px 12px;
    text-align: center;
}

.proposal-table .review-row {
    min-width: 90px;
    padding: 14px 0 !important;
}

.proposal-table .topic-title {
    padding: 2rem 12px;
}

.proposal-table tbody tr {
    vertical-align: middle;
    border-bottom: 1px solid #15273b;
}

.proposal-table tbody tr td {
    padding: 0px 12px;
    color: #6f98c3;
    text-align: center;
}

.proposal-table .topic-title span {
    font-weight: 300;
    font-size: 12px;
    padding-right: 1.4rem;
    /* margin-right: 12px; */
    color: #12d8e7;
}

.proposal-table tbody tr:last-child {
    border-bottom: 0 !important;
    border-color: transparent !important;
}

.proposal-table tbody tr:last-child .topic-title {
    padding-bottom: 0;
}

.stars {
    padding: 0;
}

.stars li {
    display: inline-block;
    font-size: 12px;
}

.stars li .voted {
    color: #dec917;
}

.stars li button {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    color: #6f98c3;
}

/* proposal table */

/* proposal detail */
.proposal-details {
    font-size: 14px;
    font-weight: 400;
    color: #728fa9;
    margin-bottom: 8px;
}

.proposal-header {
    padding: 0px 0px 20px 0;
    border-bottom: 1px solid #0e1b49;
    border-radius: 0;
}

.proposal-details h1 {
    color: white;
    font-size: 20px;
    margin: 0;
    font-weight: 400;
}

.proposal-details h1 i {
    color: #0adbe3;
}

.proposal-info {
    text-transform: uppercase;
}

.proposal-info span {
    font-weight: 300;
    font-size: 12px;
    padding-right: 1.4rem;
    color: #12d8e7;
}

.proposal-info span:last-child {
    padding-right: 0;
}

.proposal-content {
    line-height: 1.8;
}

.proposal-content p {
    line-height: 1.8;
    margin-bottom: 10px;
    font-size: 15px;
}
.proposal-bottom {
    margin-top: 2.4rem;
}
/*region Markdown Preview CSS*/
.proposal-content * {
    color: #9bb6ce !important;
    border: 0 !important;
    font-size: 14px;
    background: no-repeat;
    line-height: 1.8;
}
.proposal-content h2 {
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 18px !important;
    display: inline-block;
    color: white !important;
}
.proposal-content h3 {
    display: inline-block;
    margin: 0;
    font-size: 20px;
    color: white !important;
}
.proposal-content ol, .proposal-content ul {
    margin: 0 !important;
    padding: 0 !important;
    white-space: normal;
}

.proposal-content ol li p, .proposal-content ul li p {
    margin: 0;
}

.proposal-content ol li, .proposal-content ul li {
    padding: 0 !important;
    display: block !important;
    white-space: normal;
    margin-bottom: 15px;
}
.proposal-content code, .proposal-content pre {
    background: #070d20 !important;
}
.post-content .wmde-markdown {
    color: #728fa9;
    line-height: 1.8;
    font-size: 14px;
    background: none;
}
/*endregion*/

.topic-owner {
    justify-content: flex-start !important;
    display: flex !important;
    align-items: center !important;
    margin-bottom: 24px;
    margin-top: 24px;
}

.topic-owner .user-info {
    padding-left: 10px;
}

.user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}
.user-info .post-date {
    font-size: 12px;
}

.user-info p {
    margin: 0;
}

.user-info .username {
    color: #ffffff;
    font-size: 16px;
}

.user-avatar img {
    max-width: 100%;
}

.post {
    position: relative;
    justify-content: flex-start !important;
    display: flex !important;
}
.post-container {
    margin-bottom: 2rem;
}
.post-content {
    padding-left: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #728fa9;
    line-height: 1.8;
}

.post-content p:last-child {
    margin-bottom: 0;
}

.post-content .user-info {
    display: flex !important;
    align-items: start !important;
    justify-content: space-between!important;
    margin-bottom: 12px;
}
.post-button {
    background: none;
    border: 0;
    font-size: 13px;
    color: #9bb6ce;
}
.post-bottom {
    align-items: center !important;
    justify-content: space-between !important;
    display: flex !important;
    margin-top: 30px;
}
.replies > .replies {
    padding-left: 30px;
    margin-bottom: 30px;
}
.replies > .replies > .replies {
    padding-left: 0;
}
.post-container .replies .post {
    border-bottom: 1px solid #706d6d1a;
    margin-bottom: 26px;
    padding-bottom: 26px;
}
.post-container .replies .post:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.reply-button {
    color: #0adbe3;
    background: no-repeat;
    border: 0;
    padding-left: 0;
}
.reply-button:hover {
    color: #4bf8ff;
}
.replies .post-container .post-container .card-style-2 {
    background: #070d22;
    margin-top: 20px;
}

.like-buttons {
    min-width: 60px;
    width: 60px;
    display: flex;
}

.like-buttons button {
    background: no-repeat;
    border: 0;
    color: #728fa9;
    font-size: 18px;
}

.like-buttons button:hover {
    color: #0adbe3;
}
.proposal-table .like-buttons {
    margin: 0px auto;
}

.proposal-table .like-buttons button {
    font-size: 16px;
}
.dropdown-button {
    color: #728fa9;
    background: none;
    border: 0;
}

.dropdown-button:hover {
    color: #12d8e7;
}

.replies-item {
    margin-top: 20px;
    background: #070b1fa3;
    padding: 20px;
    border-radius: 4px;
}

.replier .username {
    color: #ffffff;
    font-size: 16px;
}

/* proposal detail */
/* rating */
.proposal-table .MuiRating-root {
    scale: 0.8;
}
.proposal-details .MuiRating-root {
    font-size: 20px;
}
/* rating */
/* contributors */
.contributors h2 {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 400;
    padding: 0px 0px 20px 0;
    border-bottom: 1px solid #0e1b49;
    border-radius: 0;
}

.contributors ul {
    margin: 0;
    padding: 0;
}

.contributors ul li {
    margin-bottom: 24px;
    position: relative;
    justify-content: space-between;
    display: flex !important;
    color: white;
    align-items: center !important;
}

.contributors ul li:last-child {
    margin-bottom: 0;
}

.contributors .contributor-details h4 {
    font-size: 15px;
    margin: 0;
}

.contributors .contributor-details {
    padding-left: 12px;
}

.contributors .contributor {
    position: relative;
    justify-content: flex-start !important;
    display: flex !important;
    color: white;
    align-items: center !important;
}

.contributor-details p {
    color: #728fa9;
    margin: 0;
}

.contributor-point p, .contributor-point span {
    margin: 0;
    color: #728fa9;
}

/* contributors */

/* hot topics */
.hot-topics h2 {
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 400;
    padding: 0px 0px 20px 0;
    border-bottom: 1px solid #0e1b49;
    border-radius: 0;
}

.hot-topics ul li {
    position: relative;
    justify-content: space-between;
    display: flex !important;
    color: white;
    align-items: center !important;
    margin-bottom: 30px;
}

.hot-topics ul li:last-child {
    margin: 0;
}

.hot-topics ul li h3 {
    font-size: 15px;
    font-weight: 300;
    padding-right: 12px;
    margin: 0;
    line-height: 1.5;
    position: relative;
    padding-left: 34px;
    color: #728fa9;
}

.hot-topics ul li span {
    min-width: 60px;
    text-align: right;
    color: #728fa9;
}

.hot-topics ul li h3:before {
    content: "\f7e4";
    font-family: "Font Awesome 6 Free";
    font-weight: 600;
    color: #d41a75;
    display: inline-block;
    position: absolute;
    left: 0;
    font-size: 24px;
    line-height: 40px;
}

.hot-topics ul li h3 a {
    color: #728fa9;
}

.hot-topics ul li h3 a:hover {
    color: white;
}

/* hot topics */


/* account */
.section-account label {
    color: white;
}

h2.title {
    font-size: 18px;
    color: white;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff0a;
    margin-bottom: 26px;
}

.section-account .form-control {
    border-radius: 9px;
    font-size: 13px;
    padding: 16px 20px;
    background: #e2e2e2;
    color: #1b1a1a;
}

.section-account .form-control::placeholder {
    color: #1b1a1a;
}

/* account */

/* modal */

.user-modal-header {
    justify-content: space-between !important;
    display: flex !important;
    margin-bottom: 10px;

}

.close-modal {
    border: 0;
    color: white;
    position: absolute;
    right: 12px;
    top: 12px;
    background: #1c2435;
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 13px;
    border-radius: 50%;
}

.close-modal:hover {
    background: #000000d1;
}

.user-modal-header h3 {
    font-size: 22px;
    font-weight: 100;
    color: white;
}

.user-modal .modal-content {
    background: #070c16;
    border-radius: 32px;
}

.user-form .form-label {
    color: #d4d4d4;
}

.user-form .form-control {
    border-radius: 9px;
    font-size: 13px;
    padding: 16px 20px;
}

.user-form .form-floating > label {
    color: white;
    font-size: 12px;
    padding: 1.2rem 1.2rem;
}

.user-form > .form-row i {
    position: absolute;
    right: 25px;
    bottom: 20px;
    font-size: 16px;
    color: #070c16;
}

.user-form > div {
    position: relative;
}
.error-messages {
    color: #c63636;
    padding: 0;
    border-radius: 4px;
    font-weight: 500;
}
.modal-backdrop {
    /* background: black;
    opacity: 0.6 !important; */
    background: #000000cc;
    opacity: 1 !important;
}

.user-button {
    background: #07588d;
    border: 0;
    color: white;
    border-radius: 9px;
    padding: 16px 3rem;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    width: 100%;
    margin-top: 10px;
    transition: all .4s ease-out 0s;
}

.user-button:hover {
    background: #116fad;
}

.form-content {
    width: 70%;
    max-width: 488px;
    padding: 0rem 2rem;
}

.modal-wrapper {
    justify-content: space-between !important;
    display: flex !important;
}

.modal-image {
    background: url(../src/assets/earth.png) no-repeat 0 0 transparent;
    width: 277px;
    background-position: 0 0;
    height: 401px;
}

/* modal */

/* message modal */
.proposal-template-button {
    background: no-repeat;
    box-shadow: none;
    border: 0;
    padding: 0px 6px;
}
.proposal-template-button {
    background: no-repeat;
    box-shadow: none;
    border: 0;
    padding: 0px 6px;
}

.proposal-template-body {
    background: #0e0f24;
    padding: 16px;
    color: #b1c2cf;
    margin-bottom: 2rem;
    border-radius: 9px;
}

.proposal-template-body h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 28px;
    color: white;
}

.proposal-template-body p {
    margin-bottom: 5px;
}
.proposal-template-button:after {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #d63384;
    margin-left:8px;
}
.proposal-template-button[aria-expanded="false"]:after {
    content: "\f13a";
}

.proposal-template-button[aria-expanded="true"]:after {
     content: "\f139";
}
.w-md-editor-text {
    min-height: 100% !important;
}
.message-modal {
    height: 100% !important;
    background-color: #151c2c;
    background-image: url('../src/assets/body-bg.jpg');
}
.message-modal .offcanvas-header {
    padding: 21px 30px;
    border-bottom: 1px solid #ffffff1a;
    margin-bottom: 3rem;
}
.message-modal .close-modal {
    background: #dc18ec;
}
.message-modal .close-modal:hover {
    background: #cf13e0;
}
.message-body textarea {
    border: 0;
    color: white;
    width: 100%;
    font-size: 14px;
}

.message-body input {
    width: 100%;
    border: 0;
    padding: 20px 20px;
    border-radius: 9px;
    color: white;
    font-size: 14px;
}

.message-body input::placeholder {
    color: #b1c2cf;
}

.message-body input {
    background: #131128a6;
    color: #ebeff2;
}
.message-body label {
    color: #b1c2cf;
    font-size: 14px;
}
.message-body input:focus {
    border: 0;
    box-shadow: none;
    outline: none;
    background: #100e24a6;
}
.btn-save {
    padding: 0;
    color: #3dd0cb;
}

.btn-save:hover {
    color: #4eede8;
}
/* message modal */
@media (max-width: 1180px) {
    header {
        padding: 20px 0;
    }
}

@media (min-width: 1366px) {
    .container {
        max-width: 1240px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}

@media (min-width: 960px) {
    .notification .dropdown-menu {
        min-width: 320px;
    }

    .proposal-header .stars {
        min-width: 120px;
        text-align: right;
    }

    .proposal-header {
        justify-content: space-between !important;
        display: flex !important;
    }

    .date-filter {
        align-items: center !important;
        justify-content: flex-start !important;
        display: flex !important;
    }

    .filter-title {
        background: #9614C4;
    }

}
@media (min-width: 760px) {
.proposal-bottom {
    display: flex!important;
    align-items: center!important;
    justify-content: space-between!important;
}

}
@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    .card-style-1 {
        margin-bottom: 1.5rem;
    }
}

@media (min-width: 1024px) and (max-width: 768px) and (orientation: portrait) {
    .card-style-1 {
        margin-bottom: 1.5rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .card-style-1 {
        margin-bottom: 1rem;
    }
}

@media (min-width: 1024px) and (max-width: 1365px) {
    body {
        background-size: cover;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    header .container-fluid {
        padding: 0px 26px;
    }
}

@media (max-width: 1024px) {
    header {
        padding: 20px 0;
    }
    .proposal-info {
        margin-bottom: 20px;
    }
}

@media (max-width: 860px) {
    .user-avatar {
        width: 36px;
        height: 36px;
    }

    .post-content {
        padding-left: 14px;
    }
    .modal-image {
        width: 100%;
        min-width: 100%;
        display: none;
    }

    .form-content {
        width: 100%;
        max-width: unset;
        padding: 20px 0;
    }

    .user-modal .modal-content {
        border-radius: 12px;
    }

    body:before {
        width: 140%;
    }

    .proposal .proposal-header h2 {
        font-size: 22px;
        margin-bottom: 0;
    }

    .liked {
        position: absolute;
        left: 0;
        top: 64px;
    }

    .card-style-1,
    .card-style-2 {
        margin-bottom: 1rem;
        padding: 24px 16px;
    }

    .date-filter {
        background: transparent;
    }

    .filter-title {
        padding: 0px 10px;
        display: none;
    }

    .filter-options {
        padding-left: 10px;
    }

    .filter-options {
        background: #061526;
        border-radius: 16px;
    }

    .filter-options li button {
        font-size: 14px;
    }

}

@media (max-width: 520px) {
    body {
        background-position: 0 0 !important;
    }

    .not-found h1 {
        font-size: 2rem;
    }
}

@media (max-width: 420px) {
    .topic-title h3 {
        font-size: 18px;
    }

    .notification-button span {
        top: 0;
        right: -2px;
    }
    .voting {
        margin-top: 20px;
        scale: 0.8;
    }
    .proposal-content h2 {
        font-size: 20px !important;
    }
    
    .proposal-content h3 {
        font-size: 18px !important;
    }
    .proposal-info span {
        padding-right: 8px;
        font-size: 11px;
    }
}

@media (max-width: 390px) {
    .search-button {
        right: 0;
    }
    .filter-options li button {
        padding: 0 10px;
    }
    .col-xs-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .xs-100 {
        width: 100%;
    }
    .proposal-table .topic-title span {
        margin-right: 12px;
        padding-right: 0;
    }
    
    .proposal-table .topic-title span i {
        margin-right: 6px !important;
    }
    .topic-row {
        width: 100% !important;
        min-width: 314px !important;
    }
    
}
.discord-button {
    background-color: #2a45a7;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    padding: 14px;
    text-decoration: none;
    transition: background-color .3s ease;
    width: 120px;
}

.discord-button:hover {
    background-color: #2f4fc4;
}

.discord-button img {
    max-width: 100%;
    vertical-align: middle; 
}
.verified-icon {
    color: #bdbd1d;
}
.verified-text {
    font-weight: 300;
    font-size: 12px;
    padding: 0px 4px;
}
.w-md-editor {
    background: #0e1024;
    box-shadow: none;
    border: 1px solid #2e2b2b;
}

.w-md-editor-toolbar li > button {
    color: white;
}

.w-md-editor-toolbar li > button:hover {
    background: transparent;
    color: #d621aa;
}
.w-md-editor-toolbar {
    background: #0c0e23;
    padding: 18px 20px;
    border-bottom: 1px solid #2e2b2b;
}
.w-md-editor-content {
    color: #9bb6ce;
}

.w-md-editor-content::-webkit-scrollbar {
    border: none;
    background-color: transparent;
 } /* webkit browsers(safari, chrome) */

.w-md-editor-content
{
    scrollbar-color: #404040b3 transparent; /*firefox*/
 }



/* new proposal */
.message-body {
    overflow-y: scroll;
}

.message-body::-webkit-scrollbar {
    border: none;
    background-color: transparent;
 } /* webkit browsers(safari, chrome) */

 .message-body
{
    scrollbar-color: #404040b3 transparent; /*firefox*/
 }